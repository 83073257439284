import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import { QRSVGFromString } from "./GenQR";
import XXH from 'xxhashjs';
const hasher = XXH.h32(0xFAAC);

/*
Base64 characters are : a-z A-Z 0-9 + /

Let's use a data uri scheme to identify types of data then
*/

function PartsForData(type: string, data: string) {
}

export type QrossQRData = {
  num_parts: number,
  type: 'text' | 'file',
  /** Base64-encoded filename (so transfers don't get weird) */
  filename?: string,
  data: Record<number, string>
}

const data_cutoff_length = 512;

export function EncodeQrossQRData(
  data: string,
  props: {
    type?: 'text' | 'file'
    filename?: string,
  } = {}
): QrossQRData {
  let { filename } = props;
  const { type = 'text' } = props;
  if (!filename) {
    filename = hasher.update(data).digest().toString(16);
  }
  if (data.length < data_cutoff_length) {
    return {
      num_parts: 1,
      type,
      filename,
      data: {
        0: data
      }
    }
  }
  console.log('datalen > ', data_cutoff_length);
  const numFullParts = Math.floor(data.length / data_cutoff_length);
  const remainderLength = data.length - numFullParts * data_cutoff_length;
  const numParts = remainderLength > 0 ? numFullParts + 1 : numFullParts;

  const dataMap: Record<number, string> = {};
  for (let i = 0; i < numParts; i++) {
    dataMap[i] = data.slice(data_cutoff_length * i, data_cutoff_length * (i + 1))
  }
  return {
    num_parts: numParts,
    type,
    filename,
    data: dataMap
  }
}

export function ParseQrossQRData(data: string, previousData?: QrossQRData): QrossQRData | undefined {
  // We only find commaIdx in the first 300 characters to save time
  // Rationale : 256 = APFS max file length + some possible extra data
  const commaIdx = data.slice(0, 300).indexOf(',');
  if (commaIdx < 0) {
    return undefined;
  }
  const headerPart = data.slice(0, commaIdx);
  const dataPart = data.slice(commaIdx + 1);

  if (!headerPart.length) {
    // Data is a string.
    return {
      num_parts: 1,
      type: 'text',
      data: {
        0: dataPart
      }
    };
  }
  else {
    const headerParts = headerPart.split(';');
    const outdata: Partial<QrossQRData> = {
      type: 'text'
    };
    const requiredParamsSet = new Set(['n', 'p']);
    for (const hpart of headerParts) {
      const [key, value] = hpart.split(':');
      requiredParamsSet.delete(key);
      switch (key) {
        case 'n': { // Number of parts
          outdata.num_parts = Number(value);
        } break;
        case 'p': { // Part number
          outdata.data = { [value]: dataPart };
        } break;
        case 'nm': { // File name
          outdata.filename = value;
        } break;
        case 'f': {
          outdata.type = (value == '1') ? 'file' : 'text';
        }
      }
    }
    if (requiredParamsSet.size) {
      console.error('RequiredParams left', Array.from(requiredParamsSet), requiredParamsSet.size);
      return;
    }
    const verifiedData = outdata as QrossQRData;
    if (previousData) {

      // Verify if filename is the same and the number of parts is the same
      if (
        previousData.filename == verifiedData.filename
        && previousData.num_parts == verifiedData.num_parts
        && previousData.type == verifiedData.type
      ) {
        // If same, add the new data to the data field of the previous object
        for (let k in verifiedData.data) {
          previousData.data[k] = verifiedData.data[k];
        }
        // Return the previous object
        return previousData;
      }
      // If fail check, let fall through and just return this current data
    }
    return verifiedData;
  }
}
class AnimatedQRJoiner {
  data: string[] = [];

  constructor() {
  }
}

const base64 = {
  encode: (arg: string) => window.btoa(arg),
  decode: (arg: string) => window.atob(arg)
}

export const QROSS_DEFAULT_QR_SIZE = 320;

export const QrossQRDisplay = React.memo((props: {
  data: QrossQRData,
  fps?: number,
  size?: number,
  quietZone?: number,
  color?: string,
  shouldAnimate?: boolean
}) => {
  const {
    data,
    fps = 3,
    size = QROSS_DEFAULT_QR_SIZE,
    quietZone = 0,
    color = '#000',
    shouldAnimate = true
  } = props;
  // console.log('showing', data);

  const [displayIndex, setDisplayIndex] = useState(0);
  const displayIndexRef = useRef(displayIndex);
  displayIndexRef.current = displayIndex;
  const [displayItems, setDisplayItems] = useState<Record<number, { path: string, cellSize: number }>>({});
  const [headerFront, setHeaderFront] = React.useState('');
  useEffect(() => {
    setDisplayItems({});
  }, [data, size]);
  useEffect(() => {
    if (data.filename) {
      const encodedFilename = data.filename ? base64.encode(unescape(encodeURIComponent(data.filename))) : undefined;
      setHeaderFront(`n:${data.num_parts};nm:${encodedFilename};f:${data.type == 'file' ? 1 : 0}`);
    }
    else {
      setHeaderFront(`n:${data.num_parts};f:${data.type == 'file' ? 1 : 0}`);
    }
    // console.log('to generate map');
    // GenerateQRMapForData(data, size).then((v)=>{
    //   setDisplayItems(v);
    // })
  }, [data, size]);

  useEffect(() => {
    setDisplayIndex(0);
    if (!shouldAnimate) return;
    const interval = 1000 / fps;
    const tmo = setInterval(() => {
      let nextIdx = displayIndexRef.current + 1;
      if (nextIdx >= data.num_parts) {
        nextIdx = 0;
      }
      setDisplayIndex(nextIdx);
    }, interval);
    return () => {
      setDisplayIndex(0);
      clearInterval(tmo);
    }
  }, [data, fps, shouldAnimate]);

  let displayData = displayItems[displayIndex];
  if (!displayData) {
    const d = data.data[displayIndex];
    if (!d || !d.length) return <NoQrossDataView size={size} />

    const fullStr = `${headerFront};p:${displayIndex},${d}`;
    displayData = QRSVGFromString(fullStr, size);
    displayItems[displayIndex] = displayData;
  }
  return <svg
    viewBox={[
      -quietZone,
      -quietZone,
      size + quietZone * 2,
      size + quietZone * 2
    ].join(' ')}
    style={{ width: size, height: size, backgroundColor: '#FFF' }}
    width={size}
    height={size}
  >
    <path
      d={displayData.path}
      strokeLinecap='butt'
      stroke={color}
      strokeWidth={displayData.cellSize}
    />
  </svg>
  // return <QRCode
  //   size={size}
  //   value={data.data[0]}
  // />
});

export const NoQrossDataView = React.memo((props: {
  size?: number
}) => {
  const { size = QROSS_DEFAULT_QR_SIZE } = props;
  return <div
    style={{
      width: size, height: size,
      backgroundColor: 'black',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <p style={{ fontSize: 18, color: '#FAFAFA' }}>No data to send</p>
  </div>
})

export const GeneralQRUtility = React.memo((props: {
  style?: React.CSSProperties
}) => {
  const { style } = props;
  const [qrText, setQRText] = useState('');
  return <div style={style}>
    <textarea value={qrText} placeholder='Enter text to send here' onChange={(e) => { setQRText(e.target.value) }} style={{ minHeight: 80, marginBottom: 4 }} />
    {qrText.length > 0 ?
      <GeneralQRDisplay data={qrText} />
      :
      <div
        style={{
          width: QROSS_DEFAULT_QR_SIZE, height: QROSS_DEFAULT_QR_SIZE,
          backgroundColor: 'black',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <p style={{ fontSize: 18, color: '#FAFAFA' }}>No data to send</p>
      </div>
    }
  </div>
})

export const GeneralQRDisplay = React.memo((props: {
  data: string,
  size?: number,
  quietZone?: number,
  color?: string,
  shouldAnimate?: boolean
}) => {
  const {
    data,
    size = QROSS_DEFAULT_QR_SIZE,
    quietZone = 0,
    color = '#000',
    shouldAnimate = true
  } = props;
  // console.log('showing', data);

  const displayData = useMemo(() => {
    return QRSVGFromString(data, size);
  }, [data]);

  return <svg
    viewBox={[
      -quietZone,
      -quietZone,
      size + quietZone * 2,
      size + quietZone * 2
    ].join(' ')}
    style={{ width: size, height: size, backgroundColor: '#FFF' }}
    width={size}
    height={size}
  >
    <path
      d={displayData.path}
      strokeLinecap='butt'
      stroke={color}
      strokeWidth={displayData.cellSize}
    />
  </svg>
  // return <QRCode
  //   size={size}
  //   value={data.data[0]}
  // />
});