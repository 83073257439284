/* eslint-disable no-lone-blocks */
import React from 'react';
import './App.css';

import EZParasite from './EZParasite'
import SnappyLog from './SnappyLog'
import MEP from './MEPaddleBall'
import About from './About'
import MathExpress from './MathExpress'
import Privacy from './Privacy'
import LanguageMistake from './LanguageMistake'
import CryptoWatch from './CryptoWatch'
import VRDemo from './VRDemo';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  Navigate
} from "react-router-dom";
import TransStellar from './TransStellar';
import Longevity from './Longevity';
import Qross from './Qross';
import EZSplit from './EZSplit';
import ZodToTS from './ZodToTS';

import routes from './routes.json';
const PUBLIC_URL = process.env.PUBLIC_URL;

export default class RouterApp extends React.Component {
  componentDidMount() {
    document.title = "Switt's Plasticware";
    // // Your AdSense code
    // // <script data-ad-client="ca-pub-8499922611982033" async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
    // const installGoogleAds = () => {
    //   const elem = document.createElement("script");
    //   elem.src =
    //     "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
    //   elem['data-ad-client'] = "ca-pub-8499922611982033";
    //   elem.async = true;
    //   elem.defer = true;
    //   document.head.insertBefore(elem, document.head.firstChild);
    // };
    // installGoogleAds();
    // (adsbygoogle = window.adsbygoogle || []).push({});
  }
  render() {
    let topBar = routes.map(({ title, link }) => {
      return <button key={`HDR.${title}`} style={{ margin: 6, backgroundColor: '#eab', borderRadius: 6 }} onClick={() => {
      }}><Link to={`/${link}`}>{title}</Link></button>

    })
    return (
      <Router basename="/site">
        <div className="App" style={{ minHeight: '100vh' }}>
          <div
            style={styles.titleText}
          >
            Switt's Plasticware (Switt's Software + Hardware)
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', borderWidth: 2, backgroundColor: '#969', flexWrap: 'wrap' }}>
            {topBar}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, backgroundColor: '#222' }}>
              <Routes>
                <Route path="/" element={<div style={{ color: '#999', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <br />HOME: Switt's Software/Hardware<br />
                  <br />Select one of the pages above to read more.
                  <div style={{ padding: 10, borderRadius: 20, backgroundColor: '#DD6', margin: 16 }}>
                    <img src={`${PUBLIC_URL}/svg/swoft.svg`} style={{ width: 120, height: 120 }} />
                  </div>
                </div>}>
                </Route>
                <Route path="/snappylog" element={<SnappyLog />}>
                </Route>
                <Route path="/monsterevasionpaddleball" element={<MEP />}>
                </Route>
                <Route path="/ezparasite" element={<EZParasite />}>
                </Route>
                <Route path="/mathexpress" element={<MathExpress />}>
                </Route>
                <Route path="/languagemistake" element={<LanguageMistake />}>
                </Route>
                <Route path="/cryptowatch" element={<CryptoWatch />}>
                </Route>
                <Route path="/transstellar" element={<TransStellar />}>
                </Route>
                <Route path='/longevity' element={<Longevity />}>
                </Route>
                <Route path='/qross' element={<Qross />}>
                </Route>
                <Route path='/vrdemo' element={<VRDemo />}>
                </Route>
                <Route path='/zodtots' element={<ZodToTS />}>
                </Route>
                <Route path="/about" element={<About />}>
                </Route>
                <Route path="/privacy" element={<Privacy />}>
                </Route>
                <Route path="/ezsplit" element={<EZSplit />}>
                </Route>
              </Routes>
            </div>
          </div>
        </div>
      </Router>
    );
  }
}

// class App extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       /** @type {String} */
//       page: 'home'
//     }
//   }
//   render() {
//     const { page } = this.state;
//     const links = [
//       { title: 'Home', mode: 'home' },
//       { title: 'SnappyLog', mode: 'snappylog' },
//       // {title:'Longevity Online', link:},
//       { title: 'Monster Evasion Paddleball', mode: 'monsterevasionpaddleball' },
//       { title: 'EZParasite', mode: 'ezparasite' },
//       // {title:'Synthetiltzer', link:'synthetiltzer.php'}
//     ]
//     let topBar = links.map(({ title, link, mode }) => {
//       if (mode) {
//         return <button key={`HDR.${title}`} style={{ margin: 16, backgroundColor: '#eab' }} onClick={() => {
//           this.setState({ page: mode });
//         }}>{title}</button>
//       }
//       else {
//         return <button style={{ margin: 16, backgroundColor: '#eab' }}
//           onClick={(e) => {
//             e.preventDefault();
//             window.location.href = link;
//           }}
//         >{title}</button>
//       }
//     })

//     let renPage;
//     switch (page) {
//       default:
//       case 'home': {
//         renPage = <div style={{ color: '#999' }}>
//           <br />HOME: Switt's Software/Hardware<br /><br />Click on one of the pages to read more.
//         </div>
//       } break;
//       case 'snappylog': {
//         renPage = <SnappyLog />;
//       } break;
//       case 'ezparasite': {
//         renPage = <EZParasite />;
//       } break;
//       case 'monsterevasionpaddleball': {
//         renPage = <MEP />;
//       }
//     }

//     return (
//       <div className="App" style={{ minHeight: '100vh' }}>
//         <div
//           style={styles.titleText}
//         >
//           Switt's Plasticware (Switt's Software + Hardware)
//         </div>
//         <div style={{ display: 'flex', flexDirection: 'row', borderWidth: 2, backgroundColor: '#969' }}>
//           {topBar}
//         </div>
//         <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
//           <div style={{ display: 'flex', flexDirection: 'column', flex: 1, backgroundColor: '#222' }}>
//             {renPage}
//           </div>
//           {/* <div style={{ flex: 0.3, backgroundColor: '#999' }}>
//             <p
//               style={{
//                 fontSize: 40,
//                 color: '#888'
//               }}
//             >
//               Hardware (to be announced)
//             </p>
//           </div> */}
//         </div>
//       </div>
//     );
//   }
// }

// export default App;


const styles: { [s: string]: React.CSSProperties; } = {
  container: {
    backgroundColor: 'red',
  },
  titleText: {
    fontSize: 28,
    color: 'orange',
    textAlign: 'left',
    padding: 8,
    backgroundColor: 'teal'
  }
}
