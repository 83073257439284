import React from 'react';

export default function SnappyLog(props: { style?: React.CSSProperties }) {
  const { style } = props;
  return <div style={{ color: '#999' }}>
    <h1>Snappylog</h1>
    <h3>What it is</h3>
    A quick (hence the name<span style={{ fontStyle: 'italic' }}> snappy</span>) log entering app, designed to make diary entry less tedious for certain people.<br />
    <br />
    <span style={{ fontWeight: 'bold' }}></span>
    <h3>Rationale</h3>
    Have you ever written a diary? If you have, do you still do?<br />
    If you still do consistently, then this may not be the app for you.<br />
    <br />
    If you no longer consistently write your diary, then keep reading.<br />
    <br />
    One of the most common things that happen when people start writing a diary is this:<br />
    <br />
    You realize you want to record your memories, and so you start a diary.<br />
    After a few days of picking up the ol' notebook and writing, one day you realize.. hmm.. there's nothing much happening today.<br />
    <br />
    "What should I write? There's nothing to write!"<br />
    <br />
    Then you go to sleep. And after a few days, you forget about the diary completely, occasionally feeling a bit of guilt.<br />
    <br />
    <br />
    <br />
    <h4><span style={{ fontStyle: 'italic' }}>What I propose</span></h4>
    Snappylog tries to make diary entering feel less formal, and tries to help you form a habit.<br />
    <br />
    You are reminded each day at predetermined times (which you can set).<br />
    <br />
    When you open the app, you are shown a button to enter the day's log.<br />
    <br />
    You are then asked to rate the day's satisfaction from 1-5.<br />
    <br />
    <span style={{ fontStyle: 'italic' }}>"Form a habit"</span><br />
    You can leave the day at just a number from 1-5 and enter nothing else.<br />
    That makes it effortless enough for you to open the app and enter a number.<br />
    <br />
    After that there's the data entry (which could be skipped).<br />
    <br />
    There are 2 ways to enter data in Snappylog.<br />
    <br />
    1. Briefs<br />
    <div style={{ marginLeft: "40px" }}>This is a quick and simple way to enter your data.<br />
      <br />
      Pull down the brief adder and type a short brief/topic.<br />
      <br />
      Then tick if the brief is something good or bad (or both).<br />
      <br />
      Add a photo if you want.<br />
      <br />
      And if it's a topic you want to elaborate upon, just add more details to the brief.<br />
    </div>
    <br />
    2. Text<br />
    <div style={{ marginLeft: "40px" }}>This is the traditional way of diary keeping, kept in case you want to write.<br />
      <br />
      Personally, I rarely use this.<br />
    </div>
    <br />
    <br />
  </div >
}