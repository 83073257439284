import React from 'react';

const disclaimers = [
  'The contents provided in this app is an *extremely simplified* summary, intended purely for pre-clinical education, and is NOT to be used as reference for clinical practice.',
  `DO NOT USE ANY INFORMATION HERE AS BASIS FOR CLINICAL PRACTICE. INFORMATION MAY NOT BE UP TO DATE/NOT APPLICABLE TO YOUR REGIONS.`,
  `ANY ACTION YOU TAKE UPON THE INFORMATION IN THIS APP IS STRICTLY AT YOUR OWN RISK AND WE WILL NOT BE LIABLE FOR ANY LOSSES AND DAMAGES IN CONNECTION WITH THE USE OF OUR APPLICATION.`
]
let PUBLIC_URL = process.env.PUBLIC_URL;

const references = `Data presented in this app is based on a combination of contents on Chulalongkorn university website (http://cai.md.chula.ac.th/lesson.html (accessed July 2020)), Lecture notes at Faculty of Medicine, Chulalongkorn university, THE book : Diagnostic Medical Parasitology, 6th Edition, and the CDC website (for some updated lifecycle information)`
class EZParasite extends React.Component {
  render() {
    let { style } = this.props;
    return (
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', color: '#999', ...style }}>
        <div style={styles.h1}>EZ Parasites</div>
        <div style={styles.h2}>View and recognize common/interesting parasites</div>
        <p>
          "Everyone being at home in quarantine and yet we still have an upcoming microscope-based exam."
        </p>
        <p>
          This app is for reviewing a few common gastrointestinal parasites, with some uncommon, yet interesting ones included.
        </p>
        <p style={styles.h2}>Features</p>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: 10 }}>
          <p>• Preview of forms of different parasites (Most importantly, the diagnostic form)</p>
          <p>• Simple "Preview" of size of parasites when viewed under different microscope zoom levels</p>
          <p>• Comparison mode : Compare forms of parasites</p>
          <p>• Simplified data for simple review of some parasites; note that data may not be complete for some parasites.</p>
        </div>
        <p>{references}</p>
        <div style={{ backgroundColor: 'orange' }}>
          <p style={{ ...styles.h1, color: 'black' }}>Disclaimer</p>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: 10, color: 'black' }}>
            {disclaimers.map((v, idx) => {
              return <p key={idx}>{`• ${v}`}</p>
            })}
          </div>
        </div>
        <div>
          <p style={{fontSize:24}}>Downloads</p>
          {/* - iOS : <a href="http://itunes.apple.com/app/monster-evasion-paddleball/id875207399">App store link</a><br /> */}
          - Android<br />
          &nbsp;<a href={`${PUBLIC_URL}/binaries/EZParasitology11Aug.apk`} download style={{color:'cyan'}}>Download from this site :3</a><br /><br/>
          - iOS <br/>
          &nbsp;
          <a href={`https://apps.apple.com/app/id1526704606`} target="_blank" rel="noopener noreferrer" style={{color:'cyan'}}>
          Download From The App Store!<br/><br/>
            <img border="0" alt="Download on the App Store" src={`${PUBLIC_URL}/available-on-the-app-store.svg`} height={50}/>
          </a>
          <br />
          <br />

        </div>
      </div>
    );
  }
}

export default EZParasite;

/**
 * @type {Object.<string, React.CSSProperties>}
 */
const styles = {
  container: {
    backgroundColor: 'red',
  },
  h1: {
    fontSize: 24,
    color: '#88B',
    textAlign: 'left',
    padding: 8,
  },
  h2: {
    fontSize: 18,
    color: '#AAA',
    textAlign: 'left',
    padding: 8,
  }

}
