import React from 'react';
let PUBLIC_URL = process.env.PUBLIC_URL;

export default function LanguageMistake(props: { style?: React.CSSProperties }) {
  const { style } = props;
  return <div style={{ color: '#999', ...(style || {}) }}>
    <h1><img style={{ width: 114, height: 114 }} src={`${PUBLIC_URL}/images/languagemistake1024.png`} alt="" /> Language Mistake</h1>
    <br />
    A simple app. For bilingual users<br />
    <br />
    Accidentally picked wrong language/keyboard layout? No problem. LanguageMistake got you covered :)<br />
    <br />
    <br />
    <br />
  </div>
}