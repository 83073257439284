import QRCode, { QRCodeErrorCorrectionLevel } from 'qrcode'

const genMatrix = (value: string, errorCorrectionLevel: QRCodeErrorCorrectionLevel = 'M') => {
  const a = new Uint8Array();
  const arr = Array.prototype.slice.call(QRCode.create(value, { errorCorrectionLevel }).modules.data as Uint8Array, 0)
  const sqrt = Math.sqrt(arr.length)
  return arr.reduce((rows, key, index) => (index % sqrt === 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) && rows, []) as any[][];
}

const transformMatrixIntoPath = (matrix: ReturnType<typeof genMatrix>, size: number) => {
  const cellSize = size / matrix.length
  let path = ''
  matrix.forEach((row, i) => {
    let needDraw = false
    row.forEach((column, j) => {
      if (column) {
        if (!needDraw) {
          path += `M${cellSize * j} ${cellSize / 2 + cellSize * i} `
          needDraw = true
        }
        if (needDraw && j === matrix.length - 1) {
          path += `L${cellSize * (j + 1)} ${cellSize / 2 + cellSize * i} `
        }
      } else {
        if (needDraw) {
          path += `L${cellSize * j} ${cellSize / 2 + cellSize * i} `
          needDraw = false
        }
      }
    })
  })
  return {
    cellSize,
    path
  }
}

export function QRSVGFromString(str: string, size: number, errorCorrection: QRCodeErrorCorrectionLevel = 'M') {
  return transformMatrixIntoPath(genMatrix(str, errorCorrection), size);
}