import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
const PUBLIC_URL = process.env.PUBLIC_URL;


function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export default function Longevity({ style }: { style?: React.CSSProperties }) {
  const query = useQuery();
  const ref = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    console.log('width', ref.current ? ref.current.offsetWidth : 0);
  }, [ref.current]);

  const appRedirect = query.get('toapp');
  if (appRedirect) {
    const pid = query.get('pid');
    const mode = query.get('mode')
    if (mode == 'friendadd') {
      return <LongevityAppRedirectorRender appUrl={`longevity://--/friendadd?id=${pid}`} />
    }
  }



  return <div style={localStyles.longevityPage} ref={ref}>
    <div style={localStyles.longevityBox}>
      <img src={`${PUBLIC_URL}/images/longevitylogo.png`} alt="Longevity Logo" style={{ width: 114, height: 114, borderRadius: 10, margin: 10 }} />
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
        <h1 style={{ fontSize: 32, margin: 0 }}>Longevity</h1>
        <p style={{ fontSize: 16, marginTop: -2, alignSelf: 'flex-end' }}>4-in-a-row</p>
      </div>
    </div>
    <br />
    <div style={{ height: 1, backgroundColor: '#000' }} />
    <p>Classic 4-in-a-row game, with a bit more thinking involved ;)</p>
    <br />
    Add pieces to the board.
    <br />
    Get at least 4 of your pieces in a straight line to get a score!
    <br />
    Pieces have "longevity" values. They only stay for a specific number of turns that you select!
    <br />
    <img src={`${PUBLIC_URL}/images/longevitypreview.gif`} alt="Game Preview GIF" style={{ alignSelf: 'center', width: 375, height: 591, borderRadius: 10, margin: 10 }} />
    <br />
    Real Time Online matches are also available!<br />
    <br />
    <br />
    <a href={`https://apps.apple.com/app/longevity-4-in-a-row/id951000673`} target="_blank" rel="noopener noreferrer" style={{ color: 'cyan' }}>
      Download From The App Store!<br /><br />
      <img alt="Download on the App Store" src={`${PUBLIC_URL}/available-on-the-app-store.svg`} height={50} />
    </a>
    <div style={localStyles.privacyBox}>
      <h4>Privacy policy</h4>
      <p>- Data stored from account creation can only be used by the user, and is used nowhere else nor is used for any other purposes.</p>
      <p>- No data is retrieved from linked accounts –– not even email addresses. We purely allow you to use alternative ways to login, that is all.</p>
    </div>
    <br />
    <br />
  </div>
}

function LongevityAppRedirectorRender(props: { appUrl: string }) {
  const { appUrl } = props;
  useEffect(() => {
    const now = Date.now();
    window.location.href = appUrl;
    const tmo = setTimeout(function () {
      if (Date.now() - now > 100) return;
      // Redirect to app store if could not go to app.
      window.location.href = "https://apps.apple.com/app/longevity-4-in-a-row/id951000673";
    }, 100);
    return () => {
      clearTimeout(tmo);
    }
  }, []);
  return <></>;
}



interface StyleSheet {
  [key: string]: React.CSSProperties;
}
const localStyles: StyleSheet = {
  longevityPage: {
    backgroundColor: '#FAFAFA',
    display: 'flex',
    flexDirection: 'column'
  },
  longevityBox: {
    margin: 8,
    padding: 8,
    borderRadius: 10,
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    borderWidth: 1,
    borderColor: '#EEEEEE'
  },
  privacyBox: {
    margin: 8,
    padding: 8,
    borderRadius: 10,
    backgroundColor: '#666',
    display: 'flex',
    alignSelf: 'center',
    borderWidth: 1,
    borderColor: '#EEEEEE',
    flexDirection: 'column',
    color: '#EEE',
    alignItems: 'flex-start'
  }
}
