import React from 'react';
let PUBLIC_URL = process.env.PUBLIC_URL;

export default function TransStellar(props: { style?: React.CSSProperties }) {
  const { style } = props;
  return <div style={{ color: '#999' }}>
    <h1>TransStellar</h1>
    <h3>What it is</h3>
    A simple application intended for live monitoring of Stellar XLM prices at the user selected exchanges, with live calculations of brokerage fees.<br />
    <br />
    <span style={{ fontWeight: 'bold' }}></span>
    <h3>Rationale</h3>
    When you live somewhere where your fiat currency isn't supported natively by major exchanges like Binance, you may often have the problem of high fees when trying to buy USDT<br />
    In my case of THB currency, Binance charges me a pretty extra of 2.5% over the actual currency conversion rate. And of course nobody likes fees.<br />
    <br />
    I found one way I could get lower fees, is by buying via exchanges in my country, then transferring over to other exchanges via Stellar Lumens (XLM), which has low to negligible transaction fees.<br />
    <br />
    This app was designed to aid in that specific process, of seeing if, at the current exchange rates of Stellar lumens, and accounting for the Brokerage fees, if this route is still cheaper than simply buying via Fiat.<br />
    <br />
    <h1>Trans<img style={{ width: 114, height: 114 }} src={`${PUBLIC_URL}/images/stellartransparent.png`} alt="" />Stellar</h1>
    <br />
    Privacy disclaimer : We do not keep track of any data at all. The app is fully self-contained.
    <br />
    <br />
  </div >
}