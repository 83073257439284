import React from 'react';
const PUBLIC_URL = process.env.PUBLIC_URL;

export default function CryptoWatch(props: { style?: React.CSSProperties }) {
  const { style } = props;
  return <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, backgroundColor: 'rgb(34, 34, 34)' }}>
      <div style={{ backgroundColor: 'rgb(255, 255, 255)' }}>
        <h1>
          <img src={`${PUBLIC_URL}/images/cryptowatchlogo.png`} alt="" style={{ width: 114, height: 114, borderRadius: 10 }} />
          CryptoWatch / Notifier
        </h1>
        <br />
        Unashamedly ad-supported, I bring to you a free cryptocurrency price notifier for the Thai traders.
        <br /><br />
        Notifications are obtained by clicking on Rewarded Video Ads, after which they will be valid for 24 hours.
        <br /><br />
        For the common, getting-started traders who still can't justify subscriptions.
        <br /><br />
        Privacy disclaimer : We do NOT collect data about your trading. The data usage as seen in the App Store is purely for the ad delivery system.
        <br />
        ** BitKub integration is offered through usage of the API Key and API Secret obtained from the BitKub website's via the selection as seen in the image below. Your API Key and API Secret are encrypted locally on the device and are NOT stored or sent to any networks. None of your trading data is stored locally, or cached locally.
        <br />
        <img src="/images/bitkubapiexample.png" alt="" style={{ borderRadius: 10, margin: 8, maxWidth: '100%' }} />
      </div>
    </div>
  </div>
}