import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactMarkdown from 'react-markdown'

const PUBLIC_URL = process.env.PUBLIC_URL;


function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export default function EZSplit({ style }: { style?: React.CSSProperties }) {
  // const query = useQuery();
  const ref = React.useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(512);
  useEffect(() => {
    const gotwidth = ref.current ? ref.current.offsetWidth : 512;
    setWidth(gotwidth);
  }, [ref.current]);
  const topDetailStyle = useMemo(() => {
    return {
      display: 'flex',
      flexDirection: width > 512 ? 'row' : 'column',
      padding: 8,
      alignItems: 'flex-start'
    } as React.CSSProperties;
  }, [width]);


  return <div style={localStyles.thisPage} ref={ref}>
    <div style={localStyles.thisBox}>
      <img src={`${PUBLIC_URL}/images/ezsplitlogo.png`} alt="EZSplit Logo" style={{ width: 114, height: 114, borderRadius: 10, margin: 10 }} />
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
        <h1 style={{ fontSize: 32 }}>EZSplit</h1>
        <p style={{ fontSize: 16, marginTop: -2, alignSelf: 'flex-end' }}>Split payments easily with friends</p>
      </div>
    </div>
    <div style={{ textAlign: 'left', padding: 8 }}>
      <ReactMarkdown>
        {detailsMarkdown1}
      </ReactMarkdown>
    </div>
    {/* <br />
    <br />
    <a href={`https://apps.apple.com/app/id1566870419`} target="_blank" rel="noopener noreferrer" style={{ color: 'green', margin: 10 }}>
      Download From The App Store!<br /><br />
      <img alt="Download on the App Store" src={`${PUBLIC_URL}/available-on-the-app-store.svg`} height={50} />
    </a>
    <a href={`https://play.google.com/store/apps/details?id=swoft.qross`} target="_blank" rel="noopener noreferrer" style={{ color: 'green', margin: 10 }}>
      Get it Google Play<br /><br />
      <img alt="Download from Google Play" src={`${PUBLIC_URL}/Google_Play_Store_badge_EN.svg`} height={50} />
    </a> */}
    <div style={localStyles.privacyBox}>
      <h4>Privacy policy</h4>
      <p>- The app itself stores none of your data</p>
    </div>
    <br />
    <br />
  </div>
}

// function LongevityAppRedirectorRender(props: {appUrl: string }) {
//   const { appUrl } = props;
//   useEffect(() => {
//     const now = Date.now();
//     window.location.href = appUrl;
//     const tmo = setTimeout(function () {
//       if (Date.now() - now > 100) return;
//       // Redirect to app store if could not go to app.
//       window.location.href = "https://apps.apple.com/app/longevity-4-in-a-row/id951000673";
//     }, 100);
//     return () => {
//       clearTimeout(tmo);
//     }
//   }, []);
//   return <></>;
// }

const detailsMarkdown1 = `
If you go out with friends often (and they're generally the same groups), then you know those scenarios where you eat together, and one person pays the bill, then you pay the guy who paid later.
This is sometimes rather tedious, and generally also sometimes inaccurate.

If this scenario is part of your daily life, then EZSplit might be the app for you.

## How it works : (If this doesn't make much sense to you, don't worry about it)
   This application works on a "zero-sum" basis. Basically, when a person pays a bill, what happens is that they pay partly for their own purchases, but they also pay "extra" for other people. Basically the debts of other people could be represented as them having "excess" money, while the person who paid for them could be considered as having "deficit" money. The sum of these amounts will equal to zero.

Also we support fraction values for almost everything so it could be as precise as possible.

## How to use

1. Create a new list for the group of friends you tend to hang out with (or create a list for some event/some trip you're going to)
  - Add people to the list, and optionally (if you want) add images
  - You can also sync over people's profiles using QR codes (entirely offline) or online

2. Once you create a list, you can start adding transaction events to it (payments, refunds, etc)
  - There are two types of transactions; External and Internal.
      - External is for payments and refunds
      - Internal is for transfers between members of the group (e.g. settling debts).
  - You can enter details in various ways! You have the option to pick the type of scenario that fits your use case best
      1. Specify individual items, their prices and how much each people bought in that transaction
          - You can specify individual item prices, how much each person bought (and you can even enter fractions! Like Mr. Champ owes 1/3 of the pizza price while you owe 2/3 of the price!)
          - You can specify the paid amount to be different from the sum of the prices. This is useful for when there are discounts, etc during checkout, which causes the paid amount to be different. EZSplit will simply scale down/scale up the amount each person owes by the same ratio of difference between the total sum of the price and the actual price paid.
     2. Specify ratios between each person, and specify the total amount paid
          - This will split how much each person owes by the ratio you specify
          - This one sees use when you do things like buy multiple of the same items together (like I buy 2 sushis while Champ there buys 5 of them, and you know the total amount paid)
     3. The transaction involves all people in the list, equally
          - A rarely used option, but you'd be glad it exists sometimes

3. (At this point you've added a transaction) Look at who owes who how much
  - At the top of the list, you'll see members of the list along with their tallies of how much they owe.
  - People in the green have excess money and need to pay back others by that amount
  - People in the red have money deficit, and needs people to pay them back by that amount
  
  (The sum of all values is zero at all times)

4. Settle debts
  - Simply have people in the red pay people in the green
  - To do this, you can either
    1. using the information given by the app, discuss between yourselves, then create internal transactions to represent the settlements
    2. use the auto "Settle" button at the bottom of the screen to generate settlements for you

  - For auto Settlement suggestion generation, press on the "Settle" button at the bottom left of the screen, and it'll show you the appropriate transactions between your friends to settle your debts (who has to pay who how much)
    - simply actually pay your friends by that much and press OK to complete settlement

To sync lists between multiple devices, press the "Sync" button and follow instructions.

I hope you find this useful.`


interface StyleSheet {
  [key: string]: React.CSSProperties;
}
const localStyles: StyleSheet = {
  thisPage: {
    backgroundColor: '#a6d2ff',
    display: 'flex',
    flexDirection: 'column'
  },
  thisBox: {
    margin: 8,
    padding: 8,
    borderRadius: 10,
    backgroundColor: '#454596',
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    borderWidth: 1,
    borderColor: '#EEEEEE',
    color: '#fffced'
  },
  privacyBox: {
    margin: 8,
    padding: 8,
    borderRadius: 10,
    backgroundColor: '#666',
    display: 'flex',
    alignSelf: 'center',
    borderWidth: 1,
    borderColor: '#EEEEEE',
    flexDirection: 'column',
    color: '#EEE',
    alignItems: 'flex-start'
  }
}
