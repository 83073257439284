import React from 'react';

export default function Privacy(props: { style?: React.CSSProperties }) {
  const { style } = props;
  return <div style={{ backgroundColor: '#ccc', ...style }}>
    <h1>Privacy</h1>
    <br />
    If the app is offline only, we do not collect your data for anything at all.
    <br />
    <br />
    For our online apps, data stored from account creation can only be used by the user, and is used nowhere else nor is used for any other purposes.
    <br />
    We do not use your data for anything other than for you logging in to play games. Plain and simple.
    <br />
    <br />
    <br />
  </div>
}