import React from 'react';
let PUBLIC_URL = process.env.PUBLIC_URL;

export default function MEP(props: { style?: React.CSSProperties }) {
  const { style } = props;
  return <div style={style}>
    <h1><img style={{ width: 114, height: 114 }} src={`${PUBLIC_URL}/images/meplogo.png`} alt="" /> Monster Evasion Paddleball</h1>
    <br />
    A simple game. As you can tell it is inspired by very well known classic games.<br />
    <br />
    The goal is to survive as long as possible without the yellow monster eating the ball.<br />
    <br />
    <img style={{ width: 194, height: 343 }} src={`${PUBLIC_URL}/images/paddleballprev.png`} alt="" /><br />
    <br />
    Online leaderboard submission is also available.<br />
    <br />
    Available on Android and iOS.<br />
    <br />
    <br />
    <br />
    <br />
    Downloads<br />
    <br />
    - iOS : <a href="http://itunes.apple.com/app/monster-evasion-paddleball/id875207399">App store link</a><br />
    - Android<br />
    &nbsp;&nbsp;&nbsp; - <a href={`${PUBLIC_URL}/binaries/MonsterEvasionPaddleball-release.apk`} download>Download from this site :3</a><br />
    &nbsp;&nbsp;&nbsp; - <a href="http://www.amazon.com/Switt-Kongdachalert-Monster-Evasion-Paddleball/dp/B01K7H2S14/">Amazon app store link</a><br />
    <br />
  </div>
}