import React from 'react';
let PUBLIC_URL = process.env.PUBLIC_URL;

export default function MathExpress(props: { style?: React.CSSProperties }) {
  const { style } = props;
  return <div style={style}>
    <h1>Math Express</h1>
    <br />
    Do math, save the town.
    <br />
    <br />
    The goal is to survive as long as possible. Answer math questions of varying difficulty.<br />
    <br />
    Difficulty level ranges from Kindergarten level to Adults!
    <br />
    Real Time Online matches are also available.<br />
    <br />
    <br />
    Privacy policy : Data stored from account creation can only be used by the user, and is used nowhere else nor is used for any other purposes.
    <br />
    <br />
  </div>
}