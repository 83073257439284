import React from 'react';

export default function About(props: { style?: React.CSSProperties }) {
  const { style } = props;
  return <div style={{ color: '#999' }}>
    <p>General *hobby-grade* software and hardware development. Basically, this is my part-time job.</p>
    <hr />
    <p>General Support Information</p>
    <p>Switt Kongdachalert, M.D.</p>
    <p>Email : swittssoftware@gmail.com</p>
    <p>Working hours : 8 AM to 4 PM, Mon-Sat (GMT+7 time)</p>
  </div >
}