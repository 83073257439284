import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactMarkdown from 'react-markdown'
import { VRButton, Controllers, Interactive, XR } from '@react-three/xr'
import { Canvas } from '@react-three/fiber';
import { Text, Sky } from '@react-three/drei'
import { SnellenChart } from './snellen';

const PUBLIC_URL = process.env.PUBLIC_URL;

function Box({ color, size, scale, children, ...rest }: any) {
  return (
    <mesh scale={scale} {...rest}>
      <boxGeometry attach="geometry" args={size} />
      <meshPhongMaterial attach="material" color={color} />
      {children}
    </mesh>
  )
}

function Floor() {
  return (
    <mesh rotation={[-Math.PI / 2, 0, 0]}>
      <planeGeometry attach="geometry" args={[40, 40]} />
      <meshStandardMaterial attach="material" color="#666" />
    </mesh>
  )
}
const UntypedText = Text as any;


function Button(props: {
  size?: number[],
  color?: string,
  selectedColor?: string,
  text?: string,
  onSelect?: () => void,
  position?: number[]
}) {
  const {
    size,
    color = 'blue',
    selectedColor = 'yellow',
    text = '',
    onSelect
  } = props;
  const [hover, setHover] = useState(false)
  // const onSelect = () => {
  //   setColor(selectedColor);
  // }

  return (
    <Interactive onHover={() => setHover(true)} onBlur={() => setHover(false)} onSelect={onSelect}>
      <Box color={hover ? selectedColor : color} scale={hover ? [0.6, 0.6, 0.6] : [0.5, 0.5, 0.5]} size={size} {...props}>
        <UntypedText position={[0, 0, (size?.[2] || 1) / 1.9]} fontSize={0.05} color="#000" anchorX="center" anchorY="middle">
          {text}
        </UntypedText>
      </Box>
    </Interactive>
  )
}

const testDistancesFt = [20, 10, 5, 3];
const courierStdBoldFontPointsPerMm = 1.9;
const fontPointsSelection = [1.8, 1.9, 2.0, 2.1, 2.2];
function VRContentView() {
  const [distanceFt, setDistanceFt] = useState(testDistancesFt[0]);
  // const [fontPoint, setFontPoint] = useState(fontPointsSelection[0]);
  // const fontSelectionB = useMemo(() => {
  //   const spacing = 0.2;
  //   const startingX = -(testDistancesFt.length * spacing) / 2.0;
  //   return fontPointsSelection.map((v, idx) => {
  //     return <Button
  //       text={`${v}Pt`}
  //       position={[startingX + spacing * idx, 0.7, -0.7]}
  //       size={[0.15, 0.15, 0.15]}
  //       key={`${v}Pt`}
  //       onSelect={() => {
  //         setFontPoint(v);
  //       }}
  //     />;
  //   });
  // }, []);
  return <Canvas>
    <XR>
      <Sky sunPosition={[0, 1, 0]} />
      <Floor />
      <ambientLight color='white' />
      <pointLight position={[10, 10, 10]} />
      {/* <Button position={[0, 1.5, -1]} /> */}
      <RenderDistanceSelectionButtons setDistanceFt={setDistanceFt} />
      {/* {fontSelectionB} */}
      <SnellenChart position={[0, 1.5, -distanceFt * 30 / 100]} color={'green'} pointsPerMm={courierStdBoldFontPointsPerMm} />
      <UntypedText position={[0, 0.8, -0.5]} fontSize={0.05} color="orange" anchorX="center" anchorY="middle">
        {`Current Distance : ${distanceFt} Ft (${distanceFt * 30 / 100} m)`}
      </UntypedText>
      <Box color={'#F0F'} position={[0, 0.8, -distanceFt * 30 / 100]} size={[0.4, 0.1, 0.1]} />
      {/* <Box color={'#F00'} position={[0, 0, 0]} size={[0.1, 0.1, 1]} /> */}
      <Controllers />
    </XR>
  </Canvas>
}

function RenderDistanceSelectionButtons(props: { setDistanceFt: React.Dispatch<React.SetStateAction<number>> }) {
  const { setDistanceFt } = props;
  const results = useMemo(() => {
    const spacing = 0.2;
    const startingX = -(testDistancesFt.length * spacing) / 2.0;
    return testDistancesFt.reverse().map((v, idx) => {
      return <Button
        text={`${v}Ft`}
        position={[startingX + spacing * idx, 1, -1]}
        size={[0.15, 0.15, 0.15]}
        key={`${v}Ft`}
        onSelect={() => {
          setDistanceFt(v);
        }} />;
    });
  }, [setDistanceFt]);
  return <>
    {results}
  </>
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export default function VRDemo({ style }: { style?: React.CSSProperties }) {
  // const query = useQuery();
  const ref = React.useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(512);
  useEffect(() => {
    const gotwidth = ref.current ? ref.current.offsetWidth : 512;
    setWidth(gotwidth);
  }, [ref.current]);
  const topDetailStyle = useMemo(() => {
    return {
      display: 'flex',
      flexDirection: width > 512 ? 'row' : 'column',
      padding: 8,
      alignItems: 'flex-start'
    } as React.CSSProperties;
  }, [width]);


  return <div style={localStyles.thisPage} ref={ref}>
    <div style={localStyles.thisBox}>
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
        <h1 style={{ fontSize: 32 }}>Eye Chart</h1>
        <p style={{ fontSize: 16, marginTop: -2, alignSelf: 'flex-end' }}>VR Eye Chart</p>
        <p style={{ fontSize: 16, marginTop: -2, alignSelf: 'flex-end' }}>Open this page in a VR-Compatible Browser to view!</p>
      </div>
    </div>
    <VRButton />
    <VRContentView />
    {/* <div style={{ height: 1, backgroundColor: '#000' }} />
    <div id='top-detail' style={topDetailStyle}>
      <div id='qr-generator' style={{ display: 'flex', flexDirection: 'column', padding: 6, backgroundColor: '#ffba1f', borderRadius: 10, boxShadow: '2px 8px 6px #88888888' }}>
        <p style={{ fontSize: 18, fontWeight: 'bold' }}>Create QR</p>
        <p style={{ fontSize: 12, fontWeight: 'bold' }}>Scan this QR with QRoss on your other device!</p>
      </div>
      <div id='text-in-top-detail' style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', padding: 8 }}>
        <ReactMarkdown>
          {detailsMarkdown1}
        </ReactMarkdown>
      </div>
    </div>
    <br />
    <br />
    <div style={localStyles.privacyBox}>
      <h4>Privacy policy</h4>
      <p>- The app itself stores none of your data</p>
    </div> */}
    <br />
    <br />
  </div>
}

// function LongevityAppRedirectorRender(props: {appUrl: string }) {
//   const { appUrl } = props;
//   useEffect(() => {
//     const now = Date.now();
//     window.location.href = appUrl;
//     const tmo = setTimeout(function () {
//       if (Date.now() - now > 100) return;
//       // Redirect to app store if could not go to app.
//       window.location.href = "https://apps.apple.com/app/longevity-4-in-a-row/id951000673";
//     }, 100);
//     return () => {
//       clearTimeout(tmo);
//     }
//   }, []);
//   return <></>;
// }

const detailsMarkdown1 =
  `
## VR Demo
View this on your Oculus Quest, or other OpenXR compatible web browsers!
`



interface StyleSheet {
  [key: string]: React.CSSProperties;
}
const localStyles: StyleSheet = {
  thisPage: {
    backgroundColor: '#a6d2ff',
    display: 'flex',
    flexDirection: 'column'
  },
  thisBox: {
    margin: 8,
    padding: 8,
    borderRadius: 10,
    backgroundColor: '#454596',
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    borderWidth: 1,
    borderColor: '#EEEEEE',
    color: '#fffced'
  },
  privacyBox: {
    margin: 8,
    padding: 8,
    borderRadius: 10,
    backgroundColor: '#666',
    display: 'flex',
    alignSelf: 'center',
    borderWidth: 1,
    borderColor: '#EEEEEE',
    flexDirection: 'column',
    color: '#EEE',
    alignItems: 'flex-start'
  }
}
