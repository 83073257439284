import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactMarkdown from 'react-markdown'
import { Text } from '@react-three/drei'
const UntypedText = Text as any;
const PUBLIC_URL = process.env.PUBLIC_URL;

const snellen_reference_distances = [200, 100, 70, 50, 40, 30, 25, 20, 15, 10];
const snellen_letters = ['C', 'D', 'E', 'F', 'L', 'O', 'P', 'T', 'Z'];

const courierStdBoldFontPointsPerMm = 1.9;
// const fontPointsPerMm = 2.83465;

// Inspecting source code of pmdrs/drei for Text, it uses troika-three-text internally
// at https://github.com/pmndrs/drei/blob/master/src/core/Text.tsx
// where fontSize is defined as : The em-height at which to render the font, in local world units
// Courier is chosen due to multiple sources citing courier as the appropriate font (e.g. https://www.teachengineering.org/content/cub_/activities/cub_human/cub_human_lesson06_activity1_eyechart.pdf)
const fontLocationURL = `${PUBLIC_URL}/fonts/courier_std_bold.otf`;

// Snellen chart = 5 arcminutes at specified distance at each line (arcminute = 1/60 degree)

// "Correspondingly, a 26-mm letter occupies the 20/60 line of the Snellen chart at the standard testing distance of 20 ft."

const fiveArcminInRadians = 5 / 60 * Math.PI / 180;

// Verify this with distanceFt = 60 ft >> Get 26.1799 mm >> Math checks out!
// So.. this means the largest text (200 Ft line) should be around 87.266 mm high
function sizeFor5ArcminDistanceFt(distanceFt: number) {
  const distanceMm = distanceFt * 30 * 10;
  // As we know, at small angles the SIN() is approximately the same
  const fiveArcminHeight = Math.sin(fiveArcminInRadians) * distanceMm;

  return fiveArcminHeight;
}

function SnellenLine(props: {
  distance: number,
  position?: number[],
  pointsPerMm?: number
}) {
  const {
    distance,
    position,
    pointsPerMm = courierStdBoldFontPointsPerMm
  } = props;
  const [numCharacters, characterSizeMM] = React.useMemo(() => {
    // Character size in mm
    const characterSizeMM = sizeFor5ArcminDistanceFt(distance);

    return [Math.floor(200 / distance), characterSizeMM];
  }, [distance]);
  const characters = React.useMemo(() => {
    const chars: string[] = [];
    for (let i = 0; i < numCharacters; i++) {
      chars.push(snellen_letters[Math.floor(Math.random() * snellen_letters.length)]);
    }
    return chars;
  }, [numCharacters]);
  return <>
    <UntypedText font={fontLocationURL} position={position} fontSize={characterSizeMM * pointsPerMm / 1000} color="#000" anchorX="center" anchorY="middle">
      {characters.join(' ')}
    </UntypedText>
    {/* <Box size={[characterSizeMM / 1000, characterSizeMM / 1000, characterSizeMM / 1000]} color='#0F0' position={[0.2, position?.[1] || 0, position?.[2] || 0]} />
    <UntypedText font={fontLocationURL} position={[0.2, position?.[1] || 0, position?.[2] || 0 + characterSizeMM / 2000 + 0.01]} fontSize={0.05} color="#000" anchorX="center" anchorY="middle">
      {`${characterSizeMM}mm`}
    </UntypedText> */}
    <UntypedText font={fontLocationURL} position={[(position?.[0] || 0) + 0.2, position?.[1] || 0, position?.[2] || 0]} fontSize={0.01} color="#000" anchorX="center" anchorY="middle">
      {`20/${distance}`}
    </UntypedText>
  </>
}

function Box({ color, size, scale, children, ...rest }: any) {
  return (
    <mesh scale={scale} {...rest}>
      <boxBufferGeometry attach="geometry" args={size} />
      <meshPhongMaterial attach="material" color={color} />
      {children}
    </mesh>
  )
}

export function SnellenChart(props: {
  color?: string,
  scale?: number,
  position?: [number, number, number],
  pointsPerMm?: number
}) {
  const {
    color = 'white',
    scale = 1,
    position,
    pointsPerMm
  } = props;
  const [snellen_z_positions, snellen_sum_position] = React.useMemo(() => {
    let accum_position_z = 0;
    const zPositions = snellen_reference_distances.map((v) => {
      const sizeMm = sizeFor5ArcminDistanceFt(v);
      let myZ = accum_position_z;
      accum_position_z += sizeMm * 1.1; // Have the vertical spacing be 10% character height
      return myZ;
    });
    return [zPositions, accum_position_z]
  }, []);
  return (
    <mesh scale={scale} position={position}>
      <meshPhongMaterial attach="material" color={color} />
      {snellen_reference_distances.map((v, idx) => {
        return <SnellenLine
          distance={v}
          position={[0, (snellen_sum_position / 2.0 - snellen_z_positions[idx]) / 1000, 0]}
          pointsPerMm={pointsPerMm}
          key={'distance'+v}
        />
      })}
    </mesh>
  )
}